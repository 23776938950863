.filter-item {
	padding: 0px 0px;
	padding-left: 15px;

	border-radius: 4px;
	border: 2px solid #999;

	margin: 2px 0px;

	font-weight: bold;
	font-size: 1rem;

	color: #999;
	background-color: #fff;

	-webkit-transition: color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,
    	background-color 0.15s ease-in-out,
    	border-color 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out,
    	background-color 0.15s ease-in-out,
    	border-color 0.15s ease-in-out;
}
.filter-item:hover {
	background-color: #999;
	color: #fff;
}
.filter-item.filter-heading {
	padding-right: 15px;
	color: #aaa;
	background-color: #fff;
}
.filter-item.filter-heading:hover {
	background-color: #aaa;
	color: #fff;
}
.filter-item.active {
	border: 2px solid #24b314;
	background-color: rgba(36, 179, 20, 0.5);
	color: #fff;
}
.filter-item.active:hover {
	background-color: #24b314;
}

.cms-alert {
	position: fixed;
	padding: 1.25rem 1.5rem 1rem 1.5rem;
	font-size: 1.25rem;
	font-weight: 500;
	left: 250px;
	right: 250px;
	border-radius: 4px;
	color: #6e6446;
	transition: opacity 0.5s ease-in-out,
		top 0.5s ease-in-out;
}
.cms-alert > button.close {
	font-size: 1.5rem;
	font-weight: 900;
	padding-right: 5px;
}
.cms-alert.hide {
	opacity: 0;
	top: 0px;
}
.cms-alert.show {
	opacity: 1;
	top: 400px;
}
.capability-name {
  font-weight: bold;
  font-size: 1.75rem; }

.contact-name {
  font-weight: bold;
  font-size: 1.5rem; }

.label-header {
  font-weight: bold;
  font-size: 1.1rem; }

.capability-section {
  margin: 10px 0px; }

.row-instructions {
  font-weight: bold;
  font-size: 1rem; }

.accordion-container {
  border: solid 1px #334152;
  border-radius: 4px;
  margin-bottom: 15px; }

.accordion-header {
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.25s ease-in-out; }

.accordion-header.opened {
  margin-bottom: 15px; }

.accordion-header:hover {
  background-color: #ddd;
  cursor: pointer; }

.accordion-instructions {
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 1rem;
  padding-left: 20px;
  margin-top: -5px; }
